<template>
    <div class="notice-container">
        <div class="title mr-b2">
            设备预约记录
        </div>
        <div class="list mr-b6" v-if="list.length">
            <DeviceItem
                v-for="item in list"
                :item="item.deviceId"
                :stock="false"
                :action="false"
                :key="item._id"
            >
                <div slot="right">
                    <div class="content">
                        {{ "预约数量" | language }}：{{ item.amount }}
                    </div>
                    <div class="content">
                        {{ "预约时间" | language }}：{{
                            item.reservedAt | fmt_time
                        }}
                    </div>
                    <div class="content">
                        {{ "预约状态" | language }}：{{
                            item.status | fmt_status
                        }}
                    </div>
                </div>
            </DeviceItem>
        </div>
        <error
            :text="'您暂时没有预约设备~'"
            class="mr-b4"
            :show="true"
            v-else
        ></error>
    </div>
</template>

<script>
import Error from "../../compontents/Error";
import DeviceItem from "../../compontents/service/device/DeviceItem";
export default {
    components: {
        Error,
        DeviceItem
    },
    data() {
        return {
            list: [],
            total: 0,
            pageSize: 10,
            pageNum: 1
        };
    },
    created() {
        if (!this.admin) {
            this.$router.push("/common/login");
        } else {
            this.fetchData();
        }
    },
    filters: {
        fmt_status: val => {
            let map = {
                reserve: " 已预约",
                refuse: " 已拒绝",
                approve: " 已同意",
                borrow: " 外借中",
                return: " 已归还",
                overdue: " 已超期（归还期均为30天）"
            };
            return map[val];
        }
    },
    methods: {
        async fetchData() {
            let res = await this.$http.post("/deviceNew/reserve/listByUser");
            this.list = res.result;
            this.total = res.total;
        }
    }
};
</script>

<style lang="less" scoped>
.notice-container {
    .title {
        opacity: 0.9;
        font-size: 30px;
        line-height: 32px;
        text-align: left;
    }
    .list {
        display: flex;
        flex-wrap: wrap;
        .item {
            margin: 1vw 1vw 0 0;
        }
    }
}
@media screen and (max-width: 960px) {
    .notice-container {
        .list {
            .item {
                height: auto;
                margin: 4vw 0 0 0;
                width: 100%;
                /deep/.item-container {
                    flex-direction: column;
                    .right {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
</style>
