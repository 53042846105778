<template>
    <div class="item" v-if="item && Object.keys(item).length">
        <div class="title ellipse">{{ item.name }}</div>
        <div class="item-container">
            <img class="img img-fit" v-real-img="item.image.src" />
            <div class="right">
                <div class="content">
                    {{ "类别" | language }}：{{ item.categoryName }}
                </div>
                <div class="content">
                    {{ "编号" | language }}：{{ item.deviceNo }}
                </div>
                <div class="content">
                    {{ "规格" | language }}：{{ item.spec }}
                </div>
                <div class="content">
                    {{ "品牌" | language }}：{{ item.band }}
                </div>
                <slot name="right"></slot>
            </div>
        </div>
        <slot name="result"></slot>
        <div class="stock" v-if="stock">
            {{ "库存" | language }}<span class="num">{{ item.stock }}</span
            >{{ "套" | language }}
        </div>
        <Button
            class="btn"
            type="primary"
            style="width:120px;"
            v-if="action"
            :disabled="!btnText(item).show"
            @click="goToDetail(item)"
            >{{ btnText(item).text | language }}</Button
        >
    </div>
</template>

<script>
import { languageTranslation } from "../../../assets/util/language";
export default {
    props: {
        item: Object,
        select: Array,
        stock: {
            type: Boolean,
            default: true
        },
        action: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {};
    },
    computed: {},
    methods: {
        goToDetail(item) {
            if (item.status == "available") {
                this.$store.commit("set_device_type", this.select);
                return this.$router.push(
                    `/base/service/device/detail/${item._id}`
                );
            } else {
                this.$Message.error(languageTranslation("不可预约"));
            }
        },
        btnText(item) {
            if (!item.status) {
                console.log(item);
                return;
            }
            if (item.status == "available") {
                return {
                    show: true,
                    text: "立即预约"
                };
            } else if (item.status == "suspend") {
                return { show: false, text: "暂停预约" };
            } else if (item.status == "outstock") {
                return {
                    show: false,
                    text: "暂无库存"
                };
            }
        }
    }
};
</script>

<style lang="less" scoped>
.item {
    width: 365px;
    height: 305px;

    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.18);
    padding: 20px;
    .title {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        margin-bottom: 20px;
    }
    .item-container {
        margin-bottom: 8px;
        display: flex;
        align-items: center;

        .img {
            width: 106px;
            height: 106px;
            flex-shrink: 0;
        }
        .right {
            margin-left: 30px;
            flex: 1;
            .content {
                margin-bottom: 12px;
                width: 100%;
                height: 20px;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 20px;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    // .img {
    //   width: 216px;
    //   height: 124px;
    //   position: relative;
    // }
    // .stock {
    //   margin-top: 10px;
    //   margin-bottom: 20px;
    //   width: 100%;
    //   text-align: left;
    //   height: 22px;
    //   font-size: 16px;
    //   font-weight: 400;
    //   color: rgba(0, 0, 0, 0.5);
    //   line-height: 22px;
    //   .num {
    //     color: #f7b500;
    //     margin-left: 5px;
    //     margin-right: 5px;
    //   }
    // }
    .stock {
        // margin-top: 10px;
        // margin-bottom: 20px;
        margin-left: 137px;
        width: 100%;
        text-align: left;
        height: 29px;
        font-size: 16px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 29px;
        .num {
            color: #fa6400;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .btn {
        margin-top: 15px;
        margin-left: 60px;
    }
}
</style>
