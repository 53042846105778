<template>
    <div class="notice-container">
        <div class="title mr-b2">
            新消息通知
        </div>
        <div class="list mr-b6" v-if="list.length">
            <div
                class="item"
                v-for="item in list"
                :key="item._id"
                @click="handleClick(item)"
            >
                <div
                    class="circle succ"
                    :class="{ inject: !item.isApproved, succ: item.isApproved }"
                ></div>
                <div class="_right-content mr-l1">
                    <div class="_title">
                        {{ item.isApproved ? "审核成功通知" : "审核拒绝通知" }}
                    </div>
                    <div class="_content">
                        <span class="text">{{ item.content }}</span>
                        <span
                            class="mr-l1 btn click-op"
                            v-if="
                                item.isApproved &&
                                    (item.type == 'CompetitionModel' ||
                                        item.type == 'ResearchModel')
                            "
                            >文章详情></span
                        >
                    </div>
                </div>
            </div>
        </div>
        <error
            :text="'您暂时没有消息通知~'"
            class="mr-b4"
            :show="true"
            v-else
        ></error>
    </div>
</template>

<script>
import Error from "../../compontents/Error";
export default {
    components: {
        Error
    },
    data() {
        return {
            list: [],
            total: 0,
            pageSize: 10,
            pageNum: 1
        };
    },
    created() {
        if (!this.admin) {
            this.$router.push("/common/login");
        } else {
            this.fetchData();
        }
    },
    methods: {
        handleClick(item) {
            if (item.type == "ResearchModel") {
                this.$router.push(
                    `/base/information/competition-activities/detail/${item._id}`
                );
            } else if (item.type == "CompetitionModel") {
                this.$router.push(`/base/info/project/detail/${item._id}`);
            }
        },
        async fetchData() {
            let res = await this.$http.get("/user/notification");
            this.list = res.result;
            this.total = res.total;
        }
    }
};
</script>

<style lang="less" scoped>
.notice-container {
    .title {
        opacity: 0.9;
        font-size: 30px;
        line-height: 32px;
        text-align: left;
    }
    .list {
        .item {
            padding: 15px 0;
            border-bottom: 1px solid #d5d7da;
            display: flex;
            align-items: flex-start;
            .circle {
                margin-top: 8px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: #2c8dfc;
            }
            .succ {
                background: #2c8dfc;
            }
            .inject {
                background: #f55e5a;
            }
            ._right-content {
                ._title {
                    opacity: 0.9;
                    font-size: 18px;
                    line-height: 29px;
                    text-align: left;
                }
                ._content {
                    display: flex;
                    align-items: center;
                    .text {
                        opacity: 0.6;
                        font-size: 14px;
                        line-height: 29px;
                    }
                    .btn {
                        font-size: 14px;
                        color: #2c8dfc;
                        line-height: 29px;
                    }
                }
            }
        }
    }
}
</style>
