<!--
 * @Author: Raymonda
 * @Date: 2020-07-20 00:15:21
 * @LastEditTime: 2020-07-20 00:43:52
 * @LastEditors: Raymonda
 * @Description: Description
 * @当你不能够再拥有，你唯一可以做的，就是令自己不要忘记！
-->
<template>
    <div class="notice-container">
        <div class="title mr-b2">
            场地预约记录
        </div>
        <div class="list mr-b6" v-if="list.length">
            <Card v-for="item in list" :key="item._id" class="item">
                <div class="base-cell">
                    <div class="label">{{ "场地名" | language }}</div>
                    <div class="value">{{ item.roomName }}</div>
                </div>
                <div class="base-cell">
                    <div class="label">{{ "使用时间" | language }}</div>
                    <div class="value">
                        {{ item.date }} {{ item.beginAt }}-{{ item.endAt }}
                    </div>
                </div>
                <div class="base-cell">
                    <div class="label">{{ "预约时间" | language }}</div>
                    <div class="value">
                        {{ item.createdAt | fmt_time }}
                    </div>
                </div>
                <div class="base-cell">
                    <div class="label">{{ "计划" | language }}</div>
                    <div class="value">{{ item.plan }}</div>
                </div>
                <div class="base-cell">
                    <div class="label">{{ "事由" | language }}</div>
                    <div class="value">{{ item.reason }}</div>
                </div>
                <div class="base-cell">
                    <div class="label">{{ "审批状态" | language }}</div>
                    <div class="value">{{ item.status | statusFilter }}</div>
                </div>
            </Card>
        </div>
        <error
            :text="'您暂时没有预约场地~'"
            class="mr-b4"
            :show="true"
            v-else
        ></error>
    </div>
</template>

<script>
import Error from "../../compontents/Error";
import DeviceItem from "../../compontents/service/device/DeviceItem";
export default {
    components: {
        Error,
        DeviceItem
    },
    data() {
        return {
            list: [],
            total: 0,
            pageSize: 10,
            pageNum: 1
        };
    },
    created() {
        if (!this.admin) {
            this.$router.push("/common/login");
        } else {
            this.fetchData();
        }
    },
    filters: {
        statusFilter: val => {
            let map = {
                inApproving: "审批中",
                approved: "已通过",
                reject: "已拒绝",
                expired: "到期"
            };
            return map[val];
        }
    },
    methods: {
        async fetchData() {
            let res = await this.$http.post("/roomNew/reserve/listByUser");
            this.list = res.result;
            this.total = res.total;
        }
    }
};
</script>

<style lang="less" scoped>
.notice-container {
    .title {
        opacity: 0.9;
        font-size: 30px;
        line-height: 32px;
        text-align: left;
    }
    .list {
        display: flex;
        flex-wrap: wrap;
        .item {
            margin: 1vw 1vw 0 0;
            width: 300px;
            .base-cell {
                display: flex;
                margin: 10px 0;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.85);
                .label {
                    font-weight: 500;
                }
            }
        }
    }
}
@media screen and (max-width: 960px) {
    .notice-container {
        .list {
            .item {
                height: auto;
                width: 100%;
                margin: 4vw 0 0 0;
                /deep/.item-container {
                    flex-direction: column;
                    .right {
                        margin-left: 0;
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
</style>
